<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col For Logo-->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <svg width="100" height="40" viewBox="0 0 299 135" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="32.2518" y="48.9014" width="22.7481" height="67.6054" fill="#26DE81"/>
        <line x1="42.8621" y1="32" x2="42.8621" y2="49.5597" stroke="#26DE81"/>
        <line x1="42.8621" y1="116.507" x2="42.8621" y2="134.067" stroke="#26DE81"/>
        <rect y="16.9014" width="22.7481" height="67.6054" fill="#FF231F"/>
        <line x1="10.6104" y1="8.64691e-09" x2="10.6103" y2="17.5597" stroke="#FF231F"/>
        <line x1="10.6104" y1="84.5068" x2="10.6104" y2="102.067" stroke="#FF231F"/>
      </svg>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
<!--      <locale />-->
      <dark-toggler class="d-none d-lg-block" />
<!--      <search-bar />-->
<!--      <cart-dropdown />-->
<!--      <notification-dropdown />-->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style scoped>
/*.bg-logo{*/
/*  margin-left: auto;*/
/*  margin-right: auto;*/
/*  width: 5rem;*/
/*  background-repeat: no-repeat;*/
/*  background-size: contain;*/
/*  background-position: center;*/
/*  background-image: url("../../../../../src/assets/images/logo/logo-jadid.png");*/
/*}*/
</style>